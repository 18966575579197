body {
  min-height: 100vh;
}

#logo {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

#main-section {
  text-align: center;
}

.option-btn {
  margin: 10px 0 10px 0;
  width: 250px;
  font-size: 14px;
}

td,
th {
  white-space: nowrap;
  vertical-align: middle !important;
}

.container {
  text-align: center;
}

.field-area {
  margin-top: 15px;
  margin-bottom: 15px;
}

.field-area input[type="checkbox"] {
  margin-left: 10px;
  top: 5px;
}

form {
  max-width: 800px;
  margin: 0 auto;
}

.formik-error {
  color: red;
  font-weight: bold;
}
textarea {
  min-height: 300px;
}

label {
  font-weight: bold;
}

.usapacs-form-label {
  margin: 20px auto;
}
.usapacs-select {
  max-width: 300px;
  width: 100%;
  margin: 20px auto;
}
