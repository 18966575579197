.menu {
  padding: 0;
}

.menu li {
  list-style: none;
  margin: 30px 0;
}

.menu-option-link {
  border: solid black 1px;
  padding: 5px;
  border-radius: 5px;
  background: blue;
  color: white;
  width: 200px;
  display: inline-block;
}
.menu-option-link:hover {
  color: #d4d4d4;
  text-decoration: none;
}
